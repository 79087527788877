// Window.fetch is not included in babel/polyfill
import 'whatwg-fetch';

import 'intersection-observer';

import { createApp } from 'vue';

import router from './router';
import App from './App.vue';
import store from './vuex-store';
import i18n from './lang/lang';
import siteConfig from '../config/site';

import './css/main_style.css';
import './css/toggle-switch.css';
import './css/print.css';

// Router navigation guard:
// Handles locale switching and redirecting from root URL to language-specific
// homepage. Also handles setting title & metadata.
// TODO: fix for Vue3
router.beforeEach((to, from, next) => {
  // Language handling.
  let language = to.params.locale;
  // Params can't be set on these routes so they need to be set manually
  if (to.name === 'home-es') {
    language = 'es';
  } else if (to.name === 'home-en') {
    // If user was redirected from the root URL, check browser language first.
    if (from.path === '/' && language === 'es') {
      language = navigator.language.toLowerCase().substr(0, 2);
      next('home-es');
    } else {
      language = 'en';
    }
  }

  if (!language) {
    language = i18n.global.locale;
  } else {
    i18n.global.locale = language;
  }
  // eslint-disable-next-line no-param-reassign
  to.params.locale = language;

  // Set title
  let title = '';
  if (to.name.indexOf('home') === 0) {
    title = i18n.global.t(`strings.pageTitles.home`);
  } else {
    title = i18n.global.t(`strings.pageTitles.${to.name}`, to.params);
  }
  if (title.indexOf('strings') === 0) {
    title = i18n.global.t('strings.pageTitles.home');
  }
  document.title = title;

  // Set metadata. For now just use a single description for all pages except tracts.

  // Find old metatags.
  const metaTags = Array.from(document.querySelectorAll('[data-vue-router-controlled]'));

  let description = i18n.global.t('strings.pageMetaDescriptions.home');
  if (to.name === 'infosheet' && store.getters.dataLoaded) {
    // Use dynamic tract diabetes data in meta if possible.
    // @TODO: Replace this with better text.
    description = store.getters.currentTractTitle;
  }

  const metaTagDefinitions = {
    description: {
      content:
        description,
    },
    ogTitle: {
      content:
        title,
    },
    ogUrl: {
      content:
        siteConfig.baseUrl + to.fullPath,
    },
    ogDescription: {
      content:
        description,
    },
    ogType: {
      content: to.name === 'infosheet' ? 'article' : 'website',
    },
  };

  metaTags.forEach((tag) => {
    const tagDef = metaTagDefinitions[tag.getAttribute('data-vue-router-controlled')];
    if (!tagDef) { return; }
    Object.keys(tagDef).forEach((key) => { tag.setAttribute(key, tagDef[key]); });
  });

  next();
});

const app = createApp(App)
  .use(router)
  .use(i18n)
  .use(store);

app.mount('#app');
