/* eslint-disable no-param-reassign */
import "regenerator-runtime/runtime";

import { createStore } from 'vuex';
import _ from 'lodash';
import router from '../router';

const fetchResponseJSON = async (path) => {
  try {
    const response = await fetch(path);
    return await response.json();
  } catch (e) {
    return null;
  }
};

const TRACT_TEMPLATE = {
  code: '',
  name: '',
  region: '',
  fips: '',
  locationDescription: { en: [], es: [] }, // Currently unused.
  data: {},
  resources: {},
};

export default createStore({
  strict: true,
  state: {
    currentTractIndex: null,
    dataLoaded: false,
    resourceList: {},
    tractList: [
      TRACT_TEMPLATE,
    ],
    metricConfig: {},

    // Show/hide tract list overlay and mobile menu? Used to set fixed class on body.
    tractListOverlayShown: false,
    menuOverlayShown: false,
    scrollPosition: [0, 0],

    // ID of element which is currently visible, if it is being watched using v-0bserve-visibility.
    visibleElement: false,
  },
  getters: {
    dataLoaded: (state) => state.dataLoaded,
    currentTract: (state) => _.get(state.tractList, state.currentTractIndex, TRACT_TEMPLATE),
    currentTractTitle: (state) => _.get(state.tractList[state.currentTractIndex], 'name', ''),
    currentTractData: (state) => _.get(state.tractList[state.currentTractIndex], 'data', {}),
    currentTractResources: (state) => _.get(state.tractList[state.currentTractIndex], 'resources', []),
    locationDescription: (state) => _.get(state.tractList[state.currentTractIndex], 'locationDescription', { en: [], es: [] }),
    metricConfig: (state) => state.metricConfig,
    visibleElement: (state) => state.visibleElement,
    scrollPosition: (state) => state.scrollPosition,
  },
  mutations: {
    setDataLoaded: (state) => { state.dataLoaded = true; },
    changeTract: (state, n) => {
      state.currentTractIndex = n;
    },
    setResourceList: (state, data) => {
      state.resourceList = data;
    },
    setResources: (state) => {
      state.tractList[state.currentTractIndex].resources = _.get(state.resourceList, state.tractList[state.currentTractIndex].code, []);
    },
    setTractData: (state, { index, data }) => {
      state.tractList[index].data = data;
    },
    setTracts: (state, tracts) => {
      state.tractList = _.map(
        tracts,
        (tract) => _.defaults(tract, TRACT_TEMPLATE),
      );
    },
    setMetricConfig: (state, config) => {
      state.metricConfig = _.keyBy(config, (v) => v.name);
    },
    showTractListOverlay: (state) => {
      state.tractListOverlayShown = true;
    },
    hideTractListOverlay: (state) => {
      state.tractListOverlayShown = false;
    },
    showMenuOverlay: (state) => {
      state.menuOverlayShown = true;
    },
    hideMenuOverlay: (state) => {
      state.menuOverlayShown = false;
    },
    setVisibleElement: (state, id) => {
      state.visibleElement = id;
    },
    setScrollPosition: (state, pos) => {
      state.scrollPosition = pos;
    },
    setTractsGeoJSON: (state, geojson) => {
      state.tractList = state.tractList.map((tract) => {
        tract.geojson = _.find(geojson, (t) => t.properties.id === tract.fips);
        return tract;
      });
    },
  },
  actions: {
    nextTract: (context) => {
      let newTract = context.state.currentTractIndex + 1;
      if (newTract === context.state.tractList.length) newTract = 0;
      context.dispatch('changeTract', newTract);
    },
    previousTract: (context) => {
      let newTract = context.state.currentTractIndex - 1;
      if (newTract === -1) newTract = context.state.tractList.length - 1;
      context.dispatch('changeTract', newTract);
    },
    async setTractId(context, id) {
      if (!context.state.dataLoaded) {
        await context.dispatch('loadTracts');
      }
      const newTractIndex = _.findIndex(
        context.state.tractList,
        (tract) => tract.code === id,
      );
      return context.dispatch('changeTract', newTractIndex);
    },
    async changeTract({ commit, getters }, newTractIndex) {
      commit('changeTract', newTractIndex);
      const dataPath = `/data/tract/${getters.currentTract.code}`;

      // TODO: Abstract out these URLs into an API.js file?
      if (_.isEmpty(getters.currentTractData)) {
        const [tractData] = await Promise.all([
          fetchResponseJSON(`${dataPath}.json`),
        ]);
        commit('setTractData', {
          index: newTractIndex,
          data: tractData,
        });
      }

      if (_.isEmpty(getters.currentTractResources)) {
        commit('setResources');
      }

      await router.push({ name: 'infosheet', params: { id: getters.currentTract.code } });
    },
    async loadResources({ commit, state }) {
      const [resources] = await Promise.all([
        fetchResponseJSON('/data/resources.json'),
      ]);
      commit('setResourceList', resources);
      if (state.currentTractIndex) {
        commit('setResources');
      }
    },
    async loadTracts({ commit, dispatch }) {
      // TODO: Abstract out these URLs into an API.js file?
      const [tractsJSON, metricConfigJSON] = await Promise.all([
        fetchResponseJSON('/data/tracts.json'),
        fetchResponseJSON('/data/data_config.json'),
      ]);
      commit('setMetricConfig', metricConfigJSON);
      commit('setTracts', _.filter(_.sortBy(tractsJSON, (o) => Number(o.code)), (o) => o.code !== '9801'));
      dispatch('loadResources');
      commit('setDataLoaded');
      const tractsGeoJSON = await fetchResponseJSON('/data/tracts.geojson');
      commit('setTractsGeoJSON', tractsGeoJSON.features);
    },
  },
});
