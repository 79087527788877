export default {
  data() {
    // Track previous scroll position so can float to top when overlay opens.
    return {
      scrollPosition: [0, 0],
    };
  },
  computed: {
    showLocationSwitcher() { return this.$store.state.tractListOverlayShown; },
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    hideTractListOverlay() {
      this.$store.commit('hideTractListOverlay');
      this.toggleBodyClass('removeClass', 'fixed');
      window.scrollTo(...this.$store.getters.scrollPosition);
    },
    showTractListOverlay() {
      this.$store.commit('showTractListOverlay');
      this.$store.commit('setScrollPosition', [window.scrollX, window.scrollY]);
      window.scrollTo(0, 0);
      this.toggleBodyClass('addClass', 'fixed');
      this.$gtag.event('Open Tract List Overlay', '');
    },
  },
};
