<template>
  <footer>
    <div id="footer-top">
      <div class="wrapper">
        <div id="foot-line" />

        <div id="nav-items">
          <ul>
            <li v-for="item in menuItems" :key="item.name">
              <router-link :to="item.url">
                {{ $t(item.name) }}
              </router-link> |
            </li>
          </ul>
        </div>

        <div id="logos">
          <div class="logo logo1">
            <a href="https://www.dataworks-nc.org/"><img src="../../assets/img/layout/dataworks-logo.svg">
            </a>
          </div>

          <div class="logo logo2">
            <a href="https://lincolnchc.org/"><img src="../../assets/img/layout/lincoln-logo.png" alt="Lincoln Community Health Center"></a>
          </div>

          <div class="logo logo3">
            <a href="https://dcopublichealth.org"><img src="../../assets/img/layout/dco-shield-logo.png" alt="Durham County Department of Public Health"></a>
          </div>

          <div class="logo logo4">
            <a href="https://www.ctsi.duke.edu"><img src="../../assets/img/layout/duke-ctsi.png" alt="Duke Clinical and Translational Sciences Institute"></a>
          </div>

          <div id="print-logos">
            <div class="logo print logo1">
              <img src="../../assets/img/layout/print-logo.png">
            </div>

            <div class="logo print logo2">
              <img src="../../assets/img/layout/lincoln-logo.png">
            </div>
            <div class="logo print logo3">
              <img src="../../assets/img/layout/dco-shield-logo-black.png">
            </div>

            <div class="logo print logo4">
              <img src="../../assets/img/layout/duke-ctsi.png" alt="Duke Clinical and Translational Sciences Institute">
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="footer-bottom">
      <p>Copyright 2018 DataWorks</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
  props: {
    menuItems: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>

<style scoped>
    footer {
        background: #204238;
    }
    footer #nav-items ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    footer #nav-items ul li a, footer #nav-items ul li {
        color: #cee18b;
    }
    footer #nav-items ul li a {
        text-decoration: none;
        display: inline-block;
        margin: 0px 15px;
    }
    footer #nav-items ul li a:hover, #locations-list .item a:hover {
        color: white;
    }
    footer #logos {
        margin-top: 30px;
    }
    footer #logos .logo2 img {
        height: 50px;
    }
    footer #logos .logo1 img {
        height: 40px;
    }
    footer #logos .logo3 img {
        height: 70px;
        width: auto;
    }
    footer #logos .logo4 img {
        height: 35.6px;
        width: 269px;
    }

    footer #logos .logo {
        position: relative;
        z-index: 50;
    }

    #footer-bottom {
        background: #1a3930;
        text-align: center;
    }
    #footer-bottom p {
        margin: 0px;
        padding: 10px 0px;
        font-size: 11px;
        line-height: 15px;
        color: #6e8c83;
    }
    #foot-line {
        height: 4px;
        background: #cee18b;
        width: 60px;
        margin: 0 auto 20px auto;
    }
    footer .wrapper {
        padding: 50px 0px 30px 0px;
    }
    footer #nav-items ul li a {
        color: #4f4f4f;
        font-family: 'Montserrat', sans-serif;
        font-size: 15px;
        line-height: 20px;
    }
    footer #nav-items ul li a, footer #nav-items ul li {
        color: #cee18b;
    }

    @media (max-width:1000px) {
        footer #nav-items ul li {
            color: transparent;
            margin-bottom: 5px;
        }

        footer #logos .logo {
            margin-bottom: 20px;
        }
        footer #logos .logo:last-child {
            margin-bottom: 0px;
        }
    }
</style>
