<template>
  <div id="locations-overlay">
    <div id="overlay-wrapper">
      <div id="locations-container">
        <div class="right-side">
          <div class="heading">
            <h2>{{ $t('strings.tractLocations[0]') }} <span v-t="'strings.tractLocations[1]'" /></h2>
          </div>
          <tract-list @close-switcher="$emit('close-switcher')" />
        </div>

        <button id="close" @click="$emit('close-switcher')">
          <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 611.979 611.979" style="enable-background:new 0 0 611.979 611.979;" xml:space="preserve"><g><path d="M356.781,305.982L601.453,61.311c14.033-14.033,14.033-36.771,0-50.774c-14.004-14.033-36.741-14.033-50.774,0   L306.007,255.208L61.277,10.536c-14.004-14.033-36.771-14.033-50.774,0c-14.004,14.004-14.004,36.742,0,50.774l244.701,244.672   L10.503,550.684c-14.004,14.004-14.004,36.771,0,50.774c7.016,7.017,16.216,10.51,25.387,10.51c9.2,0,18.371-3.493,25.387-10.51   l244.701-244.701l244.671,244.701c7.017,7.017,16.217,10.51,25.388,10.51c9.199,0,18.399-3.493,25.387-10.51   c14.033-14.033,14.033-36.771,0-50.774L356.781,305.982z" /></g></svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TractList from './TractList';

export default {
  name: 'TractSwitcher',
  components: {
    TractList,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  // TODO: Add fixed class to body when this is visible and remove it when it isn't.
};
</script>

<style scoped>
#locations-overlay {
    position: absolute;
    z-index: 5000;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,.6);
    top: 0;
    left: 0;
    transition: opacity 0.3s ease;

}
</style>
