<template>
  <div id="home">
    <div id="home-hero">
      <div class="wrapper">
        <div id="hero-copy">
          <div class="error">
            <h1 v-t="'strings.pageNotFound'" />
            <i18n-t tag="p" keypath="strings.pageNotFoundText">
              <em>{{ path }}</em>
            </i18n-t>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {
      path: '',
    };
  },
  created() {
    // Get the current route.
    const route = this.$route;
    // Set the route path in the data.
    this.path = route.path;
  },
};
</script>
