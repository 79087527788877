import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';

export default createRouter({
  routes,
  mode: 'history',
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    // Fix to allow router.push to direct to a particular hash.
    if (to.params.hash) {
      return {
        selector: to.params.hash,
      };
    }
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0,
      y: 0,
    };
  },
});
