/**
 * Import your routes from different modules here. Eg:
 *
 * import authRoutes from auth
 */
import error from '../components/error.vue';

const Landing = () => import('../components/Landing.vue');
const Infosheet = () => import('../components/Infosheet.vue');
const About = () => import('../components/About.vue');
const Contact = () => import('../components/Contact.vue');

const routes = [
  {
    path: '/en',
    name: 'home-en',
    component: Landing,
  },
  {
    path: '/es',
    name: 'home-es',
    component: Landing,
  },
  { path: '/:locale/contact', name: 'contact', component: Contact },
  { path: '/:locale/tract/:id', name: 'infosheet', component: Infosheet },
  { path: '/:locale/about', name: 'about', component: About },
  { path: '/about', redirect: '/en/about' },
  { path: '/tract/:id', redirect: '/en/tract/:id' },
  { path: '/contact', redirect: '/en/contact' },
  { path: '/', redirect: '/en' },
  { path: '/:pathMatch(.*)', name: 'error', component: error },
];

export default routes;
