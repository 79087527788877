<template>
  <div>
    <div id="print-logo">
      <img src="../../assets/img/layout/print-logo.png">
    </div>
    <tract-switcher-overlay v-show="showLocationSwitcher" @close-switcher="hideTractListOverlay()" />
    <header>
      <language-switcher class="desktop" />
      <router-link id="logo" :to="{ name: `home-${$i18n.locale}`, params: { locale: $i18n.locale }}" tag="div">
        <img src="../../assets/img/layout/logo.svg" alt="DataWorks NC">
      </router-link>
      <div id="nav">
        <ul>
          <li><router-link v-t="'strings.menu.about'" :to="{ name: 'about', params: { locale: $i18n.locale }}" /></li>
          <li><router-link v-t="'strings.menu.contact'" :to="{ name: 'contact', params: { locale: $i18n.locale }}" /></li>
          <li id="location">
            <a href="#" @click.prevent="showTractListOverlay()"> <span>
                                                                   <svg
                                                                     version="1.1" width="20px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                                     viewBox="0 0 54.757 54.757" style="enable-background:new 0 0 54.757 54.757;" xml:space="preserve"
                                                                   >
                                                                     <path d="M40.94,5.617C37.318,1.995,32.502,0,27.38,0c-5.123,0-9.938,1.995-13.56,5.617c-6.703,6.702-7.536,19.312-1.804,26.952 L27.38,54.757L42.721,32.6C48.476,24.929,47.643,12.319,40.94,5.617z M27.557,26c-3.859,0-7-3.141-7-7s3.141-7,7-7s7,3.141,7,7 S31.416,26,27.557,26z" />
                                                                   </svg>
                                                                 </span>
              <div v-t="'strings.locations'" />
            </a>
          </li>
        </ul>
      </div>

      <div id="mobile-icon" @click.prevent="showMobileMenu">
        <span />
        <span />
        <span />
      </div>
    </header>

    <div v-show="mobileMenuVisible" id="mobile-menu">
      <div id="mobile-wrapper">
        <div id="mobile-container">
          <ul>
            <li v-for="item in menuItems" :key="item.name">
              <router-link :to="item.url">
                {{ $t(item.name) }}
              </router-link>
            </li>
          </ul>
          <language-switcher class="mobile" />
          <button id="close" @click.prevent="hideMobileMenu">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 611.979 611.979" style="enable-background:new 0 0 611.979 611.979;" xml:space="preserve"><g><path d="M356.781,305.982L601.453,61.311c14.033-14.033,14.033-36.771,0-50.774c-14.004-14.033-36.741-14.033-50.774,0   L306.007,255.208L61.277,10.536c-14.004-14.033-36.771-14.033-50.774,0c-14.004,14.004-14.004,36.742,0,50.774l244.701,244.672   L10.503,550.684c-14.004,14.004-14.004,36.771,0,50.774c7.016,7.017,16.216,10.51,25.387,10.51c9.2,0,18.371-3.493,25.387-10.51   l244.701-244.701l244.671,244.701c7.017,7.017,16.217,10.51,25.388,10.51c9.199,0,18.399-3.493,25.387-10.51   c14.033-14.033,14.033-36.771,0-50.774L356.781,305.982z" /></g></svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TractSwitcherOverlay from './TractSwitcherOverlay';
import LanguageSwitcher from './LanguageSwitcher';
import triggerTractListOverlayMixin from '../../utilities/triggerTractListOverlayMixin';

export default {
  name: 'SiteMenu',
  components: {
    TractSwitcherOverlay,
    LanguageSwitcher,
  },
  mixins: [triggerTractListOverlayMixin],
  props: {
    menuItems: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    mobileMenuVisible() {
      return this.$store.state.menuOverlayShown;
    },
  },
  methods: {
    hideMobileMenu() {
      this.$store.commit('hideMenuOverlay');
    },
    showMobileMenu() {
      this.$store.commit('showMenuOverlay');
    },
  },
};
</script>

<style scoped>
    header {
        background: #204238;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        border-bottom: 5px solid #23735c;
        position: relative;
    }
    .languages {
        margin-left: 20px;
    }
    header #logo img {
        width: 200px;
    }
    header #nav ul {
        padding: 0px;
        list-style-type: none;
        margin: 0px;
    }
    header #logo {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        width: 200px;
        height: 30px;
        top: 15px;
        cursor: pointer;
    }

    header #nav ul li a {
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        display: block;
        padding: 20px;
        cursor: pointer;
    }
    header #nav ul li a:hover, header #nav ul li#location a:hover {
        background-color: white;
        color: #204238;
    }
    header #nav ul li {
        position: relative;
    }
    header #nav ul li#location a {
        background: #dc3b67;
        padding: 20px 30px 20px 50px;
    }
    header #nav ul li#location a svg {
        fill: white;
        width: 15px;
        position: absolute;
        left: 28px;
        top: 22px;
        height: 15px;
        transition: fill .3s ease-in-out;
    }
    header #nav ul li#location a:hover svg {
        fill: #204238;
    }

    #mobile-menu {
        position: absolute;
        z-index: 5000;
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,.6);
        top: 0;
        left: 0;
    }

    #mobile-menu ul li {
      margin-bottom: 1em;
    }
    #mobile-menu ul li a {
      font-family: 'Montserrat', sans-serif;
      text-decoration: none;
      color: white;
      font-size: 40px;
      line-height: 48px;
      font-weight: bold;
      text-transform: uppercase;
      display: block;
    }

    @media (max-width:1000px) {
        header #nav ul li:not(#location), header .languages.desktop {
            display: none;
        }
        header {
            width: 100%;
        }
        #mobile-menu ul li a:hover {
            color: #cee18b;
        }
    }

    @media (max-width:1000px) and (orientation: landscape) {
        #mobile-menu ul li a {
            display: inline;
        }
    }

    @media (max-width:800px) {
        header #nav ul li#location div, #scroll {
            display: none;
        }
        header #nav ul li#location a svg {
            width: 25px;
            position: relative;
            left: 0;
            top: 0;
        }
        header #nav ul li#location a {
            padding: 0px;
        }
        header #nav ul li#location a span {
            width: 60px;
            height: 60px;
        }
        header #nav ul li#location a span, header #logo a {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
        }
        header #logo img {
            width: 160px;
        }
    }

</style>
