<template>
  <div>
    <site-menu :menu-items="menuItems" />
    <router-view />
    <site-footer :menu-items="menuItems" />
  </div>
</template>

<script>
import SiteFooter from './components/layout/SiteFooter';
import SiteMenu from './components/layout/SiteMenu';
import triggerTractListOverlayMixin from './utilities/triggerTractListOverlayMixin';

export default {
  name: 'App',
  components: {
    SiteFooter,
    SiteMenu,
  },
  mixins: [triggerTractListOverlayMixin],
  data() {
    return {
      mapboxgl: null,
      menuItems: [
        {
          url: { name: `home-${this.$i18n.locale}`, params: { locale: this.$i18n.locale } },
          name: 'strings.menu.home',
        },
        {
          url: { name: 'about', params: { locale: this.$i18n.locale } },
          name: 'strings.menu.about',
        },
        {
          url: { name: 'about', hash: '#how-to-use', params: { locale: this.$i18n.locale } },
          name: 'strings.menu.howToUse',
        },
        {
          url: { name: 'contact', params: { locale: this.$i18n.locale } },
          name: 'strings.menu.contact',
        },
        {
          url: { name: 'about', hash: '#resources', params: { locale: this.$i18n.locale } },
          name: 'strings.menu.resources',
        },
        {
          url: { name: 'about', hash: '#credits', params: { locale: this.$i18n.locale } },
          name: 'strings.menu.credits',
        },
      ],
    };
  },
  watch: {
    '$route': {
      handler() {
        this.$store.commit('hideMenuOverlay');
        this.$store.commit('hideTractListOverlay');
        this.toggleBodyClass('remove', 'fixed');
      },
      deep: true,
    },
    '$i18n.locale': function (newLocale) {
      document.documentElement.setAttribute('lang', newLocale);
      this.menuItems = [
        {
          url: { name: `home-${newLocale}`, params: { locale: newLocale } },
          name: 'strings.menu.home',
        },
        {
          url: { name: 'about', params: { locale: newLocale } },
          name: 'strings.menu.about',
        },
        {
          url: { name: 'contact', params: { locale: newLocale } },
          name: 'strings.menu.contact',
        },
        {
          url: { name: 'about', hash: '#resources', params: { locale: newLocale } },
          name: 'strings.menu.resources',
        },
        {
          url: { name: 'about', hash: '#credits', params: { locale: newLocale } },
          name: 'strings.menu.credits',
        },
      ];
    },
  },
  beforeCreate() {
    // Preload map resources so that they live on even between switching to Report and back.
    // @see https://github.com/mapbox/mapbox-gl-js/pull/9391
    import(/* webpackChunkName: "mapboxgl" */ 'mapbox-gl').then((mapboxgl) => {
      mapboxgl.prewarm();
      this.$root.mapboxgl = mapboxgl;
    });
  },
  beforeMount() {
    document.documentElement.setAttribute('lang', this.$i18n.locale);
    this.$store.dispatch('loadTracts');
  },
};
</script>

<style>
</style>
