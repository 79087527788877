import { createI18n } from 'vue-i18n';

const en = require('./en.json');
const es = require('./es.json');

const messages = {
  "en": en,
  "es": es,
};

export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});
