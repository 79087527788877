<template>
  <div class="languages">
    <fieldset>
      <div class="switch-toggle switch-candy">
        <span v-for="(lang, i) of langs" :key="i">
          <input :id="i" :value="i" type="radio" @click.prevent="changeLocale">
          <label :class="$i18n.locale===i ? 'selected' : ''" :for="i">{{ lang }}</label>
        </span>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  data() {
    return {
      langs: {
        en: 'English',
        es: 'Español',
      },
    };
  },
  methods: {
    changeLocale(e) {
      const route = {
        name: this.$route.name.indexOf('home') === 0 ? `home-${e.target.value}` : this.$route.name,
        hash: this.$route.hash,
        params: {
          id: this.$route.params.id,
          locale: e.target.value,
        },
      };
      this.$router.push(route);
      this.$i18n.locale = e.target.value;
    },
  },
};
</script>

<style scoped>
    .languages label {
        outline: none;
        border: none;
        color: white;
        background: transparent;
        padding: 7px 15px;
        font-family: 'Montserrat', sans-serif;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        width: 50%;
    }
    .languages label.selected {
        background: #dc3b67;
        border-radius: 30px;
    }

    .switch-toggle input:focus, .switch-toggle label:focus, .switch-toggle:focus, .switch-toggle a:focus {
        outline: none;
        border: none;
    }
    .languages fieldset .switch-candy a:hover {
        background-color: #0298c8;
    }
    .languages fieldset {
        margin: 0;
        border: none;
        padding: 0px;
    }
</style>
