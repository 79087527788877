<template>
  <div>
    <div id="search">
      <input
        v-model="tractSearch" type="text" :placeholder="$t('strings.tractSearchPlaceholder')" name="tract-search"
        autocomplete="off"
      >
      <!-- TODO: REMOVE THIS BUTTON SINCE THIS ISN"T ACTUALLY A SUBMITTABLE FORM -->
      <button id="submit">
        <input type="submit" value="s">
      </button>
    </div>
    <div id="locations-list">
      <div v-if="addressSearchInProgress && Object.values(tractList).length === 0" class="tract-group geocode-result">
        <div v-if="addressSearchInProgress" class="item">
          {{ $t('strings.searching') }}
        </div>
      </div>
      <div v-for="(tracts, index) in tractList" :key="index" class="tract-group">
        <div v-t="`strings.regions.${index}`" class="tract-group__title" />
        <div v-for="tract in tracts" :key="tract.code" class="item">
          <router-link v-if="tract.code" :to="{name: 'infosheet', params: { id: tract.code, locale: $i18n.locale }}">
            {{
              tract.name.replace(' and ', andText) }}<span
              class="tract__code"
            >{{ $t('strings.tract') }} {{ tract.code }}</span>
          </router-link>
        </div>
      </div>
      <div v-if="tractSearch.length && Object.values(tractList).length === 0 && !addressSearchInProgress" class="tract-group">
        <div v-t="'strings.tractNotFound'" class="tract-group__title" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

const geocodeService = require('@mapbox/mapbox-sdk/services/geocoding');

const geocoder = geocodeService({ accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN });

export default {
  name: 'TractList',
  data() {
    return {
      tractSearch: '',
      tractAddressSearchResults: [],
      addressSearchInProgress: false,
    };
  },
  computed: {
    andText() {
      return ` ${this.$t('strings.and')} `;
    },
    tractList() {
      // Match a tract if either it's code or name contains the search string,
      // *or* if it shows up in the address search results.
      const tractMatcher = (tract) => _.includes(
        `${tract.name.toLowerCase()} ${this.$t('strings.tract').toLowerCase()} ${tract.code.toLowerCase()}`,
        this.tractSearch.toLowerCase(),
      );
      return _.omitBy(_.groupBy(
        _.sortBy(_.uniqBy(_.concat(
          this.tractAddressSearchResults,
          _.filter(this.$store.state.tractList, tractMatcher),
        ), (tract) => tract.fips), (tract) => tract.name),
        'region',
      ), (value, key) => !key);
    },
  },
  watch: {
    tractSearch() {
      this.debouncedGetAddressList();
    },
  },
  created() {
    this.debouncedGetAddressList = _.debounce(this.getAddressList, 125);
    this.tractPolygons = false;
  },
  methods: {
    getAddressList() {
      if (this.tractSearch.length >= 3) {
        this.addressSearchInProgress = true;
        this.tractAddressSearchResults = [];
        geocoder.forwardGeocode({
          query: this.tractSearch,
          limit: 3,
          types: ['postcode', 'place', 'locality', 'neighborhood', 'address'],
          countries: ['US'],
          bbox: [-79.01, 35.87, -78.7, 36.15],
        }).send().then((response) => {
          if ('features' in response.body) {
            this.addressSearchInProgress = false;
            this.tractAddressSearchResults = _.uniqBy(_.map(response.body.features, ((feature) => {
              const tract = _.pick(_.find(this.$store.state.tractList, (t) => booleanPointInPolygon(feature.center, t.geojson)), ['code', 'name', 'fips', 'region']);
              tract.name = feature.place_name;
              return tract;
            }
            )), (tract) => tract.fips);
          }
        });
      } else {
        this.tractAddressSearchResults = [];
      }
    },
  },
};
</script>

<style scoped>
  #locations-list {
    clear: both;
  }

  #locations-list a:hover {
    color: white;
  }

  .tract-group {
    padding: 20px;
    width: 40%;
  }

  .tract-group__title {
    font-weight: bold;
    color: white;
  }

  .tract__code {
    font-size: 80%;
    padding-left: 0.5em;
    color: #aaa;
  }

  #search {
    border-bottom: 3px solid #028fbb;
    margin-bottom: 35px;
    padding-bottom: 30px;
  }

  .landing #search {
    border-bottom: 3px solid white;
  }

  #search button#submit {
    background: #cee18b;
    border: none;
    height: 34px;
    width: 40px;
    border-radius: 0px 10px 10px 0px;
    padding: 0px;
    transition: all .3s ease-in-out;
  }

  #search button#submit input {
    background: url(../../assets/img/layout/search.png) center/20px no-repeat;
    height: 30px;
    width: 40px;
    color: transparent;
    border: none;
  }

  #search input[type="text"]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
  }

  #search input[type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  #search input[type="text"]::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }

  #search input[type="text"] {
    background: #16b2e2;
    border: none;
    padding: 5px 5px 5px 12px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 10px 0px 0px 10px;
    width: 80%;
    min-width: 220px;
    color: white;
  }

  #search input[type="text"], #locations-list .item {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: white;
  }

  @media (max-width: 800px) {
    #search input[type="text"] {
      width: 100%;
      min-width: revert;
    }

    .tract-group {
      width: 100%;
    }
  }
</style>
